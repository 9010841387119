import React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

// Utilities
import kebabCase from "lodash/kebabCase"
import { starReplace, heatMapColorforValue } from "../components/functions"

const TagPage = props => {
  const { data } = props
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const { filteredData, query } = state
  // if we have a fileredData in state and a non-emptyQuery then
  // searchQuery then `hasSearchResults` is true
  const hasSearchResults = filteredData && query !== emptyQuery

  const allPosts = data.allMarkdownRemark.group

  const title = data.site.siteMetadata.title

  // if we have a search query then return filtered data instead of all posts; else return allPosts
  const posts = hasSearchResults ? filteredData : allPosts

  const handleInputChange = event => {
    const query = event.target.value
    const { data } = props
  
    // this is how we get all of our posts
    const posts = data.allMarkdownRemark.group || []

     // return all filtered posts
    const filteredData = posts.filter(post => {
      // destructure data from post frontmatter
      const fieldValue = post.fieldValue
      return (
        // standardize data with .toLowerCase()
        // return true if the fieldValue contains the query string
        fieldValue.toLowerCase().includes(query.toLowerCase())
      )
    })
  
    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

return (
<Layout location="Tags" title={title}>
<Seo title="All Tags" />
  <article>
    <div>
      <div className="flexcontainer">
      <h1 style={{ margin: "revert"}}><span className={posts.length !== allPosts.length ? "filtertext" : null }>{posts.length}</span> Tag{posts.length === 1 ? '' : 's'}</h1>
      <input
              type="text"
              className="inputbox"
              aria-label="Search"
              placeholder="Type to filter..."
              onChange={handleInputChange}
      />
      <StaticImage
        className="right-icon"
        placeholder="none"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/tag.png"
        width={50}
        height={50}
        quality={50}
        alt="Tag icon"
      />
      </div>
      <ul className="tag-results">
        { posts.length ? posts.map(tag => (
          <li key={tag.fieldValue}>
            <div style={heatMapColorforValue(tag.totalCount)}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              <div className="flexcontainer">
              <div>{/[1-5]star$/.test(tag.fieldValue) ? starReplace(tag.fieldValue) : tag.fieldValue}</div><div className="details">({tag.totalCount})</div>
              </div>
              </Link>
            </div>
          </li>
        )) : <p className="description">nothing left...</p>}
      </ul>
    </div>
  </article>
</Layout>
)
}

TagPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagPage

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(limit: 2000) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }
}
`